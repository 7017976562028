import React from "react"
import Helmet from "react-helmet"

import { SeoContent } from "../types"

interface SeoProps {
  data: SeoContent
}

export default function Seo({ data }: SeoProps) {
  return (
    <Helmet>
      <title>{data?.title ?? ""}</title>
      <meta name="robots" content={process.env.GATSBY_ROBOTS} />
      <meta
        name="description"
        content={data?.childContentfulMobsterBarSeoDescriptionTextNode?.description ?? ""}
      />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta
        property="og:description"
        content={data?.ogdescription?.ogdescription}
      />
      <meta property="og:title" content={data.ogtitle} />
      <meta property="og:locale" content={process.env.GATSBY_OG_LOCALE} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={data?.ogsiteName} />
      <meta property="og:url" content={process.env.GATSBY_OG_URL} />
      <meta property="og:image" content={"https:" + data?.ogimage?.file?.url} />
      <meta property="og:image:alt" content={data?.ogimage?.description} />
      <meta
        property="og:image:width"
        content={data?.ogimage?.file?.details?.image?.width}
      />
      <meta
        property="og:image:height"
        content={data?.ogimage?.file?.details?.image?.height}
      />
      <meta name="twitter:card" content={data.twittercard} />
      <meta name="twitter:title" content={data.twittertitle} />
      <meta name="twitter:description" content={data.twitterdescription} />
      <meta
        name="twitter:image"
        content={"https:" + data.twitterimage?.file?.url}
      />
    </Helmet>
  )
}
